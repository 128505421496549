<template>
  <div>
    <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay>
    <div class="w-100 d-flex justify-content-between">
      <span class="w-50">
        <b-form-input class="w-100" v-model="searchClient" @input="getClientSearch"></b-form-input>
      </span>
      <span>
        <b-button variant="primary" class="mb-1" @click="handleAdd">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </span>
    </div>
    <b-card>
      <b-table :fields="fields" :items="clients" responsive @row-clicked="RowClick" :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary">{{ ((parseInt(pagination.page) - 1) * parseInt(pagination.pageSize)) + (parseInt(data.index) + 1) }}</span>
        </template>
        <template #cell(passport_file)="{item}">
          <span v-if="item.passport_file !== null">
            <a @click="handleFileDownload(item.passport_file)" target="_blank">
              <box-icon name='cloud-download'></box-icon>
            </a>
          </span>

          <span v-if="item.passport_file === null">
            {{$t('data.no_data')}}
          </span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button variant="outline-success" size="sm" class="mr-1" @click="handlePush(data.item.id)">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div>
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-pagination
      v-model="pagination.page"
      :total-rows="total"
      :per-page="pagination.pageSize"
      @input="fetchClients"
    ></b-pagination>

    <b-modal hide-footer v-model="formModalActive" size="lg" no-close-on-backdrop>
      <AddClient :id="id" :branches="branches" @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import ExpenseForm from '@/views/directories/expense-type/components/ExpenseForm'
  import {deleteClients,getClients} from '@/api/clients/clients'
  import AddClient from '@/views/directories/clients/components/AddClient'
  // import {createBranchById} from '@/api/branchs/branchs'
  import AppConfig from '@/libs/vue-axios.config'
  import {getS3PermanentFile} from '@/api/visa/index'

  export default {
    name: 'Index',
    components: {
      AddClient,
      ExpenseForm
    },
    AppConfig,
    data() {
      return {
        id: null,
        clients: [],
        branches: [],
        loading: false,
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'full_name',
            label: this.$t('message.Full_name')
          },
          {
            key: 'birthdate',
            label: this.$t('message.Birth_date')
          },
          {
            key: 'email',
            label: this.$t('message.Email')
          },
          // {
          //   key: 'branch.id',
          //   label: this.$t('message.Branches')
          // },
          {
            key: 'passport_file',
            label: this.$t('message.PassportFile')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        formModalActive: false,
        searchClient: '',
        timeoutSet: null,
        pagination: {
          page: 1,
          pageSize: 20,
          search: this.searchClient
        },
        total: 1,
        busy: true,
      }
    },
    created() {
      this.fetchClients()
      this.loading = true;
    },
    methods: {
      fetchClients() {
        var clientResult = this.searchClient ? this.searchClient : null
        getClients({...this.pagination, search: clientResult}).then(response => {
          this.clients = response.data.data;
          this.total = response.data.total;
          this.loading = false;
          this.busy = false;
        })
      },
      getClientSearch() {
          clearTimeout(this.timeoutSet);
          this.timeoutSet = setTimeout(() => {
            this.fetchClients()
          }, 500)
      },
      handlePush(id) {
        this.$router.push({
          name: 'DirectoriesClientDetail',
          params: {
            id
          }
        })
      },
      RowClick(item) {
        this.$router.push({
          name: 'DirectoriesClientDetail',
          params: {
            id: item.id
          }
        })
      },
      handleAdd() {
        this.$router.push({name: 'ClientPost'})
      },
      handleEdit(id) {
        this.$router.push({name: 'ClientUpdate', params: { clientPutId: id}})
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchClients()
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteClients({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchClients()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      handleFileDownload(file) {
        getS3PermanentFile({link: file}).then(response => {
          // var headers = response.headers();
          var blob = new Blob([response.data.link],{type: 'application/jpg'});
          var link = document.createElement('a');
          link.href = response.data.link;
          link.target="_blank"
          link.click();
          this.downloadLoading = false;
        })
      }
    }
  }
</script>
