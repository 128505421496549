import req from '../../libs/axios';

export function getExpenceTypes(params) {
  return req({
    url: "/api/expence-types",
    method: "get",
    params
  })
}

export function showExpenceTypes(id) {
  return req({
    url: `/api/expence-types/${id}`,
    method: "get",
  })
}

export function addExpenceTypes(data) {
  return req({
    url: "/api/expence-types",
    method: "post",
    data
  })
}

export function updateExpenceTypes(data) {
  return req({
    url: `/api/expence-types/${data.id}`,
    method: "put",
    data
  })
}

export function deleteExpenceTypes(id) {
  return req({
    url: `/api/expence-types/${id}`,
    method: "delete"
  })
}


