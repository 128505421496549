<template>
  <div>
    <b-overlay :show="loading">
      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" :name="$t('message.Full_name')" rules="required">
          <b-form-group :label="$t('message.Full_name')" for="full_name">
            <b-form-input autofocus v-model="clients.full_name"></b-form-input>
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="validationContext" name="Birth Date" rules="required">
          <label>{{$t('message.Birth_date')}}</label>
          <!--      <b-form-datepicker id="birthdate-datepicker" v-model="clients.birthdate" class="mb-2"></b-form-datepicker>-->
          <input type="date" class="form-control" v-model="clients.birthdate">
          <span class="text-danger">
          {{ validationContext.errors[0] }}
        </span>
        </validation-provider>

        <b-row class="mt-2">
          <b-col md="3">
            <validation-provider #default="validationContext" name="Passport Serial" rules="required">
              <b-form-group :label="$t('message.Passport_serial')" for="passport_serial">
                <b-form-input autofocus v-model="clients.passport_serial" maxlength="2"  @input="toUpper" placeholder="AA">
                </b-form-input>
                <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="9" class="mt-2">
            <validation-provider #default="validationContext" name="Passport Number" rules="required">
              <b-form-group :label="$t('message.Passport_number')" for="passport_number">
                <b-form-input autofocus v-model="clients.passport_number" placeholder="1234567"></b-form-input>
                <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <validation-provider #default="validationContext" name="Given Date" rules="required">
              <label>{{$t('message.Given_date')}}</label>
              <input type="date" v-model="clients.given_date" class="mb-2 form-control">
              <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider #default="validationContext" name="Expire Date" rules="required">
              <label>{{$t('message.Expire_date')}}</label>
              <input type="date" v-model="clients.given_date" class="mb-2 form-control">
              <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider #default="validationContext" name="Contact number" rules="required">
              <label for="number">Number</label>
              <b-form-input v-model="clients.contacts[0].number"></b-form-input>
              <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <label for="extra_number">Extra Number</label>
            <b-form-input v-model="clients.contacts[1].number"></b-form-input>
          </b-col>
        </b-row>
        <div class="w-100 mt-2">
          <validation-provider #default="validationContext" :name="$t('message.Address')" rules="required">
            <label for="address">{{$t('message.Address')}}</label>
            <b-form-textarea placeholder="Textarea" v-model="clients.address" rows="3" />
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </validation-provider>
        </div>

        <div class="w-100">
          <b-row>
            <b-col cols="4" class="mt-2">
              <label for="passport_file">Passport File</label>
              <input type="file" @change="getImage">
            </b-col>
            <b-col cols="8" v-if="!profile.branch_id">
              <label class="mt-2">{{$t('message.Branches')}}</label>
              <b-form-select v-model="clients.branch_id" :options="branches" text-field="name" value-field="id"
                             style="height: 40px" class="w-100" />
            </b-col>
          </b-row>
        </div>

        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
          <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
        </div>

      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addClients,
    editClients,
    getClientsById
  } from '@/api/clients/clients'
  // import { getBranchById } from '@/api/branchs/branchs'

  export default {
    name: 'AddClient',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      branches: '',
      id: {
        default: null
      }
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        clients: {
          id: null,
          full_name: '',
          birthdate: '',
          passport_serial: '',
          passport_number: '',
          given_date: '',
          expire_date: '',
          branch_id: '',
          file: null,
          address: null,
          contacts: [{
              number: ''
            },
            {
              number: ''
            },
          ],
        },
        disabled: false,
      }
    },
    computed: {
      profile() {
        return this.$store.state.auth.authUser;
      }
    },
    methods: {
      getImage(event) {
        this.clients.file = event.target.files[0]
      },
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.loading = true;
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 1000)

          const form = new FormData()
          form.append('id', this.clients.id)
          form.append('full_name', this.clients.full_name)
          form.append('birthdate', this.clients.birthdate)
          form.append('passport_number', this.clients.passport_number)
          form.append('passport_serial', this.clients.passport_serial)
          form.append('given_date', this.clients.given_date)
          form.append('expire_date', this.clients.expire_date)
          form.append('branch_id', this.clients.branch_id)
          form.append('passport_file', this.clients.file);
          form.append('address', this.clients.address);
          form.append('contacts', JSON.stringify(this.clients.contacts))

          this.clients.id = this.id;
          if (this.id) {
            form.append('_method', 'put')
            // eslint-disable-next-line no-unused-vars
            editClients(form).then(res => {
              this.loading = false
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addClients(form).then(res => {
              this.loading = false
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchClientById() {
        getClientsById(this.id).then(res => {
          this.clients = res.data.data;
          this.clients.contacts = JSON.parse(this.clients.contacts);
        })
      },
      toUpper() {
        this.clients.passport_serial = this.clients.passport_serial.toUpperCase()
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchClientById()
            // this.fetchClients()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
