<template>
  <div>
    <b-overlay :show="loading">

    <validation-observer ref="refFormObserver">
      <validation-provider #default="validationContext" name="Name" rules="required">
        <b-form-group :label="$t('message.Name')" for="name">
          <b-form-input autofocus v-model="expenses.name"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>


      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
    </b-overlay>

  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addExpenceTypes,
    showExpenceTypes,
    updateExpenceTypes
  } from '@/api/expence-types/expence-types'

  export default {
    name: 'ExpenseForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        expenses: {
          id: null,
          name: '',
        },
        disabled: false,
        loading: false,
      }
    },
    methods: {
      handleSubmit() {
        const valid = this.$refs.refFormObserver.validate();
        if (valid) {
          if (this.id === null) {

            this.disabled = true;
            this.loading = true;
            setTimeout(() => {
              this.disabled = false;
              this.loading = false;
            }, 1000)
            // eslint-disable-next-line no-unused-vars
            addExpenceTypes(this.expenses).then( () => {
              this.$emit('closeModal')
            })
            
          } else {
            this.expenses.id = this.id;
            // eslint-disable-next-line no-unused-vars
            updateExpenceTypes(this.expenses).then( () => {
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchExpenseById() {
        showExpenceTypes(this.id).then(res => {
          this.expenses = res.data.data;
        })
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchExpenseById()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>